import './App.css';
import { useState } from 'react';

function App() {

  let [titles, setTitles] = useState(['asdfasdf', 'asdfeqqqqqqqqqqqqqqq', 'pasdjfmq'])
  let [thumbsup, setThumbsup] = useState(0)
  let [modal, setModal] = useState(false)
  let [title, setTitle] = useState('')
  let [newTitle, setNewTitle] = useState('');

  return (
    <div className='App'>
      <div className='nav'>
        <h4>Mr. M's Blog </h4>
      </div>

      {
        titles.map((t, i) => {
          return (
            <div className='list' key={i}>
              <h3 onClick={() => { setModal(true); setTitle(titles[i]) }}>{titles[i]} {i} <span onClick={(e) => { e.stopPropagation(); setThumbsup(thumbsup + 1) }}>👍</span> {thumbsup} <span onClick={ () => { let copy = [...titles]; copy.splice(i, 1); setTitles(copy); } }>❌</span></h3>
              <p>2022/07/27</p>
            </div>
          )
        })
      }

      <input onChange={ (e) => { setNewTitle(e.target.value) } } type="text"></input>
      <button onClick={ () => { let copy = [...titles]; copy.push(newTitle); setTitles(copy) } }>write</button>

      {
        modal === true ? <Modal titles={titles} setTitles={setTitles} title={title}></Modal> : null
      }
    </div>
  );
}

function Modal(props) {
  return (
    <div className='modal'>
      <h4>{props.title}</h4>
      <p>2022/07/27</p>
      <p>sdkajfjeas jsjd fjisadjfoaeusdf jasodfa sdjifasidf i ewifaisdhacf jsdfd aejhadjd jfjfjf  fj sdjfj we dnf jsdhfasjjfj iojslidj ifs s.</p>
      <button onClick={() => { props.setTitles(['qqqqqqqqqqqqqqqqqqqqqqqqq', 'b', 'w']) }}>edit post</button>
    </div>
  )
}

export default App;